import { Link } from 'gatsby'
import React from 'react'

type Props = {
    url: string
    color?: 'red' | 'blue'
    disable?: boolean
    children: React.ReactNode
}

const LinkButton: React.FC<Props> = ({ url, color, disable, children }) => {
    const buttonClass = color ? 'c-link-btn--' + color : 'c-link-btn'

    return (
        <Link
            className={`${buttonClass} ${disable ? 'is-disabled' : ''}`}
            to={url}
        >
            {children}
        </Link>
    )
}

export default LinkButton
