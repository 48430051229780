import * as React from "react"
import { PageMusicsQuery } from "@root/types/graphql-types"
import { SectionWrap } from "@/components/sections/SectionWrap"
import { GlobalLayout } from "@/components/GlobalLayout"
import { Head } from "@/components/Head"
import { TitleWithBgImg } from "@/components/TitleWithBgImg"
import { useImage } from "@/hooks/useImage"
import {
    ContentsColumn,
    MusicImageColumn,
    MusicLinkButton,
    MVList,
} from "./MusicsDetail.component"
import { useExpandImg } from "@/hooks/useExpandImg"

interface Props {
    data: PageMusicsQuery
}

export const MusicsDetail: React.FC<Props> = ({ data }) => {
    const { getImageProps, Image } = useImage()
    const { openFromEvent, ExpandImg } = useExpandImg()
    return (
        <GlobalLayout
            Head={
                <Head
                    title={data.contentfulMusics?.musicTitle || undefined}
                    description={`${data.contentfulMusics?.musicTitle}の詳細ページ`}
                    noIndex
                />
            }
            CoverComponent={<ExpandImg />}
        >
            <SectionWrap
                Title={
                    <TitleWithBgImg
                        title={"MUSIC INFO"}
                        BgImage={
                            <Image
                                {...getImageProps(
                                    data.contentfulSite?.siteHeroImage
                                        ?.gatsbyImageData
                                )}
                            />
                        }
                    />
                }
            >
                <article className="p-cd-info">
                    <div className="p-cd-info__top u-mb-50px">
                        <MusicImageColumn
                            Image={
                                <figure onClick={openFromEvent}>
                                    <Image
                                        {...getImageProps(
                                            data.contentfulMusics?.musicCDImage
                                                ?.gatsbyImageData
                                        )}
                                    />
                                </figure>
                            }
                            releaseDate={
                                data.contentfulMusics?.musicReleaseDate
                            }
                            cdType={data.contentfulMusics?.musicType || ""}
                        />
                        <ContentsColumn
                            title={data.contentfulMusics?.musicTitle!}
                            songList={
                                data.contentfulMusics
                                    ?.musicCDContent as string[]
                            }
                            introduction={
                                data.contentfulMusics?.musicIntroduction?.raw &&
                                JSON.parse(
                                    data.contentfulMusics?.musicIntroduction
                                        ?.raw
                                )
                            }
                        />
                    </div>

                    <div className="u-mb-100px">
                        <MusicLinkButton
                            url={
                                data.contentfulMusics
                                    ?.musicPurchaseURL as string
                            }
                            color="blue"
                        >
                            ご購入はこちら
                            <i
                                className="p-bottom-nav__icon fas fa-shopping-cart"
                                style={{
                                    color: "lightgoldenrodyellow",
                                    marginLeft: "5px",
                                }}
                            />
                        </MusicLinkButton>

                        <MusicLinkButton
                            url={
                                data.contentfulMusics
                                    ?.musicSubscriptionURL as string
                            }
                            color="red"
                        >
                            ストリーミングで聴く
                            <i
                                className="p-bottom-nav__icon fas fa-headphones"
                                style={{
                                    color: "lightgoldenrodyellow",
                                    marginLeft: "5px",
                                }}
                            />
                        </MusicLinkButton>
                    </div>

                    <MVList
                        mvList={data.contentfulMusics?.musicCDContentMV?.map(
                            (mv) => ({
                                title: mv?.title || "",
                                youtubeId: mv?.description || "",
                                Image: (
                                    <Image
                                        {...getImageProps(mv?.gatsbyImageData)}
                                    />
                                ),
                            })
                        )}
                    />
                </article>
            </SectionWrap>
        </GlobalLayout>
    )
}
