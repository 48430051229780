import React from "react"
import { Video } from "@/components/Video"
import { createToken } from "@/functions/createToken"
import { Document } from "@contentful/rich-text-types"
import LinkButton from "@/components/btn/LinkButton"
import { ArticleRichTextContent } from "@/components/ArticleRichTextContent"
import { MusicCard } from "@/components/Cards/GoodsCards"

type MusicImageColumnType = {
    Image: React.ReactElement
    cdType: string
    releaseDate: string
}
export const MusicImageColumn: React.FC<MusicImageColumnType> = ({
    Image,
    cdType,
    releaseDate,
}) => {
    return (
        <div className="p-cd-info__image-column">
            <div>
                <MusicCard
                    Image={Image}
                    releaseDate={releaseDate}
                    musicType={cdType}
                />
            </div>
        </div>
    )
}

type ContentsColumnType = {
    title: string
    songList?: string[]
    introduction?: Document
}

export const ContentsColumn: React.FC<ContentsColumnType> = ({
    title,
    songList,
    introduction,
}) => {
    return (
        <div className="p-cd-info__contents-column">
            <div className="p-cd-info__cd-title">
                <h2>
                    {title}
                    {songList && (
                        <span className={"ml-4 text-xs font-normal"}>
                            {songList.length}曲
                        </span>
                    )}
                </h2>
            </div>
            <ol className="p-cd-info__lists">
                {songList ? (
                    songList.map((l) => (
                        <li className="p-cd-info__list" key={createToken()}>
                            {l}
                        </li>
                    ))
                ) : (
                    <li>内容未登録</li>
                )}
            </ol>
            {introduction && (
                <ArticleRichTextContent richTextData={introduction} />
            )}
        </div>
    )
}

type MusicLinkButtonType = {
    color?: React.ComponentProps<typeof LinkButton>["color"]
    url?: string
    children: React.ReactNode
}

export const MusicLinkButton: React.FC<MusicLinkButtonType> = ({
    color,
    url,
    children,
}) => {
    return (
        <>
            {url && (
                <div className="p-cd-info__streaming">
                    <LinkButton url={url} color={color}>
                        {children}
                    </LinkButton>
                </div>
            )}
        </>
    )
}

type MVListType = {
    mvList?: React.ComponentProps<typeof Video>[]
}

export const MVList: React.FC<MVListType> = ({ mvList }) => {
    return (
        <>
            {mvList && (
                <div className="p-cd-info__mv">
                    <h2>収録曲のMV</h2>
                    {mvList.map((mv) => (
                        <Video
                            key={createToken()}
                            addClass=" p-cd-info__mv-content"
                            {...mv}
                        />
                    ))}
                </div>
            )}
        </>
    )
}
