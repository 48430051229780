import { graphql } from 'gatsby'
import { MusicsDetail } from '@/components/pages/Musics/Detail'

export default MusicsDetail

export const query = graphql`
    query PageMusics($slug: String!) {
        contentfulSite {
            siteHeroImage {
                gatsbyImageData(resizingBehavior: FILL)
            }
        }
        contentfulMusics(slug: { eq: $slug }) {
            musicReleaseDate(formatString: "YYYY/MM/DD")
            musicCDImage {
                gatsbyImageData(resizingBehavior: FILL, height: 300, width: 300)
            }
            musicTitle
            musicType
            slug
            musicCDContentMV {
                title
                id
                description
                gatsbyImageData(resizingBehavior: FILL, width: 1000)
            }
            musicCDContent
            musicSubscriptionURL
            musicPurchaseURL
            musicJoiningArtistName
            musicIntroduction {
                raw
            }
        }
    }
`
